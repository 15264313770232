import React, { useEffect, useState } from 'react'
import Table from "react-bootstrap/Table";
import "./Registered.css"
import axios from 'axios';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Helmet from "react-helmet"
import { API_AUTH_USERNAME,API_AUTH_PASSWORD, API_BASE_URL } from '../../Constant/Global'
import { Link } from 'react-router-dom';

const Registered = () => {
  var count = 1;

  const [registered,setRegistered]=useState([])
  const [sortOrder, setSortOrder] = useState('asc');

   
  
  useEffect(()=>{
    handaleRegister()
  },[])
  const handaleRegister=async()=>{
    const response=await axios.get(API_BASE_URL+'user/registered_users',
    {
      auth:{
        username:API_AUTH_USERNAME,
        password:API_AUTH_PASSWORD
      }
    })
    console.log(response.data.data);
    if(response.data.status){
      sessionStorage.setItem("registerUser" ,JSON.stringify(response.data.data))
    }
    setRegistered(response.data.data)
  }
  const handleSort = () => {
    const sortedData = registered.sort((a, b) => a.id - b.id);

    setRegistered(sortOrder === 'asc' ? sortedData : sortedData.reverse());
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  return (
    <div>
      <Helmet>
          <title>Registered User | SVK Consultant</title>
      </Helmet>
    <h3 className="text-center mt-5">Registered User</h3>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mt-4">
          <Table striped bordered hover variant="white" responsive>
            <thead>
              <tr>
                 <th onClick={handleSort}>Sr No {sortOrder === 'asc' ? '▲' : '▼'}</th>
                <th>FullName</th>
                <th>Address</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Qualification</th>
                <th>Pin Code</th>
                {/* <th>Resume</th> */}
                <th>Exam Center</th>
                <th>Hall Ticket</th>
                {/* <th>Payment Id </th>
                <th>Amount</th> */}
              </tr>
            </thead>
            {/* <tbody>
              <tr>
                <td>1</td>
                <td>Mark adam Singh</td>
                <td>Mumbai,Maharashtra</td>
                <td>9172348258</td>
                <td>Mark2022@gmail.com</td>
                <td>MCA</td>
                <td>411 001</td>
                <td>Mark@123</td>
                <td>Pune</td>
                <td>file</td>
                <td>1</td>
                <td>300</td>
                <td><button className='btn btn-primary'>Download</button></td>
              </tr>
            </tbody> */}
            <tbody>
              {registered.map((item)=>{
                return(
                  
                  <tr key={item.id}>
                  <td>{count++}</td>
                  <td>{item.name}</td>
                  <td>{item.address}</td>
                  <td>{item.mobile}</td>
                  <td>{item.email}</td>
                  <td>{item.qualification}</td>
                  <td>{item.pincode}</td>
                  <td>{item.nearest_city}</td>
                  <td><Link className='btn btn-success' to={item.hall_ticket} target='_blank' download><DownloadForOfflineIcon/></Link></td>
                  {/* <td>{item.payment_id}</td>
                  <td>{item.amount}</td> */}
                </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Registered
