import './App.css';
import Header from './Component/Header/Header';
import Login from './Component/Login/Login';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom"
import Registered from './Pages/Registered_User/Registered';
import Enquiry from './Pages/Enquiry/Enquiry';
import Home from "./Pages/Home/Home"
import ShowNavbar from './Component/ShowNavbar/ShowNavbar';
function App() {
  return (
    <div>
      <Router>
        <ShowNavbar>
        <Header/>
        </ShowNavbar>
        <Routes>
          <Route exact path='/' element={<Login/>}/>
          <Route exact path='/home' element={<Home/>}/>
          <Route exact path='/enquiry' element={<Enquiry/>}/>
          <Route exact path='/regisetred_user' element={<Registered/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
