import React, { useState,useEffect } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios"
import "./Enquiry.css"
import { Helmet } from "react-helmet";
import { API_AUTH_USERNAME,API_AUTH_PASSWORD, API_BASE_URL } from '../../Constant/Global'
const Enquiry = () => {
    var count = 1;
    const[enquiry,setEnquiry]=useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    useEffect(()=>{
        handaleEnquiry();
    },[])

    const handaleEnquiry=async()=>{
        const response=await axios.get(API_BASE_URL+'user/enquiries',
        {
            auth:{
              username:API_AUTH_USERNAME,
              password:API_AUTH_PASSWORD
            }
          })
          console.log(response.data.data)
          setEnquiry(response.data.data)
    }
    const handleSort = () => {
        const sortedData = enquiry.sort((a, b) => a.id - b.id);
    
        setEnquiry(sortOrder === 'asc' ? sortedData : sortedData.reverse());
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      };
  return (
    <div>
      <Helmet>
        <title>Enquiry List | SVK Consultant</title>
      </Helmet>
      <h3 className="text-center mt-5">Enquiry List</h3>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-4">
            <Table responsive striped bordered hover variant="white">
              <thead>
                <tr>
                <th onClick={handleSort}> ID {sortOrder === 'asc' ? '▼' : '▲' } </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subject</th>
                  <th>Mobile</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                    {enquiry.map((item)=>{
                        return(
                            <tr key={item.id}>
                            <td>{count++}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.subject}</td>
                            <td>{item.mobile}</td>
                            <td>{item.message}</td>
                            </tr>
                        )
                    })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
