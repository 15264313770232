import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const Home = () => {
  const navigate=useNavigate()
  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      console.log("Please Login First");
      navigate("/");
    }
  }, []);
  return (
    <div>
      
    </div>
  )
}

export default Home
